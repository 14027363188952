.progCompotop{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://s3.amazonaws.com/sswcp.org/image/outer/our+program/sswcp+program.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progCompotop h1{
    font-size: 100px;
    color:white;
    font-weight: 800;
}
.prog_img{
    width: 100%;
    height: 270px;
    object-fit: cover;
    /* filter: grayscale(1); */
}

.square {
    /* margin: 0 auto; */
    /* width: 80%; */
    height: 80%;
    aspect-ratio: 1/1;
    object-fit: cover;
}
@media (max-width:600px) {
    .progCompotop h1{
        font-size: 60px;
        color:white;
        font-weight: 800;
    }
}

@media (min-width: 1024px) {
    .square {
        width: 80%;
    }
}   