.hero-slider-next-button:focus,
.hero-slider-previous-button:focus {
  outline: none;
}

.new_sec {
  width: 100%;
  min-height: 50vh;
  padding: 2% 0;
}



.box-grey {
  width: 100%;
  min-height: 50vh;
  padding: 50px 0;
  /* background-color: rgba(233, 242, 237,0.8); */
}
.testi_flex {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}
.testi_flex h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: -10px;
}
.about_us_home_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.what_we_do_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.home-card-cta {
  display: flex;
  justify-content: start;
  align-items: end;
  margin-top: -57px;
}

.up_card_text {
  padding: 10px;
  background-color: rgba(2, 13, 7, 0.4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.up_card_textt {
  margin-top: -58px !important;
}
.up_card_text h4 {
  color: white;
}
.up_card_text a {
  font-size: 22px;
  color: white;
  margin-left: 10px;
}

.engage-cta {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 10px;
}

.image_div {
  width: 100%;
  min-height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
.image_div1 {
  width: 100%;
  min-height: 80vh;
  /* background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://sswcp.org/image/wepik-export-20230502203143.png'); */

  display: flex;
  justify-content: center;
  align-items: center;
}
.flag_div {
  text-align: center;
}
.flag_img {
  width: 50%;
  height: 230px;
  object-fit: contain;
}

.text_under {
  position: relative;
}
.text_under::after {
  content: '';
  position: absolute;
  display: block;
  background-color: #0b6240;
  height: 6px;
  width: 120px;
  left: 50%;
  bottom: -10px;
}

.slick-slide {
  width: 100%;
  box-sizing: border-box;
  /* display: block; */
  /* margin: 0 27px; */
  padding: 5px;
  /* height: 350px; */
  overflow: hidden;
  /* box-shadow:  1px 3px 5px rgba(0,0,0,0.4); */
}

.testi_img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  /*  */
}

.slick-arrow {
  background-color: white !important;
  width: 35px !important;
  height: 35px !important;
  padding: 5px !important;
  border-radius: 50%;
  box-shadow: 0px 1px 2px grey;
}
.card_box {
  width: 100%;
  /* background: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: justify; */
  padding: 5px;
  box-shadow: 0px 1px 2px grey;
}
@media (max-width: 500px) {
  .card_box {
    flex-direction: column !important;
  }
}

.sm_blog_img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.hero_img {
  width: 100%;
  height: 100vh;

  overflow: hidden;
  position: relative;
}
.hero_img img {
  width: 100%;
  height: 100vh;
}

.hero_img div {
  position: absolute !important;
  max-width: 700px !important;
  width: 90%;
  height: auto;
  padding: 15px;

  /* background: #000; */
  top: 80%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
}
.hero_img div h1 {
  color: white;
  font-weight: 800 !important;
}

.owl-dots {
  margin-top: -10px;
  z-index: 9999 !important;
}

.index-module_Wrapper__-jUiF {
  display: none !important;
}

.slick-dots {
  position: absolute;
  bottom: -17px !important;
}
