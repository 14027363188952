.page_box{
    width: 100%;
    min-height: 100vh;
    position: relative;
}
.privac h4{
    font-weight: 700;
}
.table-borderless{
    border: none !important;
}
table td , table th{
    border-top: none !important;
}
.pri_ul{
    list-style-type: none;
    padding-left: 15px;
}
.pri_ul li{
    margin: 10px 0;
}

.annual{
    padding-top: 150px;
    padding-bottom: 50px;
}
.annul_img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.annual .card{
    position: relative;
}

.upp_card_text{
    /* margin-top: -115px; */
    width: 100%;
    height: auto;
position: absolute;
bottom: 0;
    padding:10px;
    background-color: rgba(2, 13, 7, 0.4);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upp_card_text h4{
    color: white;

}
.upp_card_text a{
    font-size: 22px;
    color: white;
}
.hh_t{
    font-size: 100px;
}

.contact{
    width: 100%;
    min-height: 100vh;
    padding: 50px 0;
    border-radius: 15px;
}


.contact_inn{
    padding-top: 150px;
}

.pay_card{
    background-color: #354463;
    padding: 25px;
    
}
.pay_card .form_h{
    height: 50px;
    border: none !important;
}
.td_apply{
    width: 120px;
    /* background-color: #354463; */
}
.cur_tbody{
    border: 2px dashed rgb(205, 203, 203);
}
.pay_text{
    font-size: 65px;
  font-weight: 700;
  letter-spacing: 5px;
}

.search .card{
    border-radius: 0 !important;
    box-shadow: 0px 2px 5px grey;
    padding: 5px;
}
.search_row{
    display: flex;
    
}
.search_row_img{
    width: 220px;
    height: 200px;
    object-fit: cover;
    display: block;
    margin: auto;
    
}
.search_row a{
    color:black;
}

.search_row a:hover{
color:rgb(2, 81, 2) !important;
text-decoration: none;
}
.search_row h5{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
.search_row p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
@media (max-width:800px) {
    .pay_text{
       font-size: 45px;
    }
}
@media (max-width:600px) {
    .pay_text{
       font-size: 40px;
    }
    
}
@media (max-width:520px) {
    .search_row_img{
        width: 100%;
        height: 250px;
    }
    .search_row{
        flex-direction: column;
    }
}
@media (max-width:450px) {
    .pay_text{
       font-size: 35px;
    }
    
}