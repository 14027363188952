.engage {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url('https://s3.amazonaws.com/sswcp.org/image/outer/engage+with+us/sswcp+engage+with+us.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.engage .boxx {
  /* max-width: 650px !important;
    width: 90% ; */
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
.dd-flex {
  display: flex;
}
.surimg {
  object-fit: contain;

  height: 200px;
  display: block;
  margin-left: auto;
}
.row_sur {
  align-items: center;
}
.eng_contact {
  width: 100%;
  min-height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url('https://s3.amazonaws.com/sswcp.org/image/outer/engage+with+us/contact+us.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center_box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center_boxx {
  align-items: self-end !important;
}

.team_work_img {
  width: 120px;
  height: 120px;
  display: block;
  margin: auto;

  /* object-fit: cover; */
}

.event-card {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.event-card-date {
  margin-inline: 2rem;
  padding-inline: 1rem;
  font-size: 1.6rem;
}

.event-card-details {
  border-left: solid 2px #bdbdbd;
  border-top: none;
  max-width: max-content;
}

.event-time-location {
  width: 50%;
  justify-content: space-between;
  align-items: center;
}

.event-time-location > div {
    display: flex;
    gap: 5px;
}

@media (max-width: 768px) {
  .event-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .event-card-date {
    width: 100%;
  }

  .event-card-details {
    border-top: solid 2px #bdbdbd;
    border-left: none;
  }

  .event-time-location {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .dd-flex {
    display: block;
  }
}