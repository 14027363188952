.modal_box{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(5, 30, 48,0.5);
    position:fixed;
    z-index: 999999;
}

.modal_inn_box{
    max-width: 90%;
    width:500px ;
    height: 300px;
    
    /* border: 4px solid red; */
    position: relative;
    background-color: rgb(237, 245, 242);
}

.modal_img{
    width: 100%;
height:100%;
}
.modal_button{
    position: absolute;
    bottom:  50px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, 0%)
}

.cross_box{
    width: 25px;
    height: 25px;
    background-color: green;
    color:white;
    position: absolute;
    top:5px;
    right:5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
}

.modl .input-group {
    /* max-width:500px !important; */
    width: 90%;
   
    border-radius: 15px;
    background: white;
    margin: auto;
    padding: 3px;
    
}

.modl  .form-control {
    border:0 !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin: 2px;
    background-color: transparent;
}
.modl  .input-group-text  {
width: auto;
background: #034727;
border: 0 !important;
color: #fff;
border-radius: 15px !important;
    box-shadow: none !important;
    padding: 0 15px !important;
}

@media (max-width:500px) {
    .modal_inn_box{
        width: 90%;
    }
}