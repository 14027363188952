@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Merriweather', serif !important;
}

p {
  margin: 0;
}

.bg_light {
  background-color: rgba(234, 245, 240, 0.5);
}

.fn_40 {
  font-size: 40px;
}

.fn_50 {
  font-size: 60px !important;
}

.mig {
  margin-top: -10px;
}

.fn-color {
  color: green;
}

.fn_14 {
  font-size: 14px;
}

.fn_12 {
  font-size: 12px;
}

.fn_10 {
  font-size: 10px;
}

.fn_18 {
  font-size: 18px;
}

.fn_24 {
  font-size: 24px;
}

.fn_20 {
  font-size: 20px;
  line-height: 45px;
}

.fb_600 {
  font-weight: 600;
}

.fb_700 {
  font-weight: 700;
}

.text_grey {
  color: rgb(89, 89, 89);
}

.title_text {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 3px;
}

.about_text {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 5px;
}

.title_text_Sm {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 3px;
}

.small_text {
  font-size: 30px;
}

.low_border {
  position: relative;

}

.low_border_inn {
  position: absolute;
  width: 100px;
  height: 5px;
  background-color: #0B6240;
  margin-top: -10px;
}

.normal_font {
  word-spacing: 8px;
  font-size: 16px;
}

.text-custom {
  color: #0B6240 !important;

}

.btn-primary {
  background-color: #0B6240 !important;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 30px;
}

.btn-primary_black {
  background-color: #353535 !important;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;

}

a:hover {
  text-decoration: none !important;
}

.ex_h {
  height: 400px !important;
}

.btn-primary:hover {
  background-color: #038e38 !important;
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}

.btn-one {
  background: #0a4260 !important;
  padding: 7px 13px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;


}

.btn-one-mix {
  background: linear-gradient(to right, #0b5075, #3792c2) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.btn-onee-mix {
  background: linear-gradient(to right, #0b5075, #3792c2) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.btn-two {
  background: #8b0303 !important;
  padding: 7px 13px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;

}

.btn-two-mix {
  background: linear-gradient(130deg, #dc0606, yellow) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.btn-three {
  background: #a60357 !important;
  padding: 7px 13px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;

}

.btn-three-mix {
  background: linear-gradient(to right, #c20366, #f32424) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}



.blank_page {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(199, 194, 194);
}

.cur {
  cursor: pointer !important;
}

.mbneg {
  margin-bottom: -7px;
}

.matchcom {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-wrap: wrap;
}

.masmall {
  padding: 2px 7px;
  background: grey;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  font-size: 10px;
  margin: 1px;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-warning {
  background-color: #C1F7D5 !important;
  border: none !important;
  border-radius: 15px;
}

.form-control {
  border: 1px solid #E0E0E0 !important;

}

.form-control:focus {

  box-shadow: none;
}

.wid {
  max-width: 70%;
  display: block;
  margin: auto;
}

@media (max-width:500px) {
  .wid {
    max-width: 100%;

  }

  .about_text {
    font-size: 35px !important;
    font-weight: 700;
    letter-spacing: 3px;
  }

  .small_text {
    font-size: 25px;
  }
}

@media (min-width:750px) and (max-width:1000px) {
  .wid {
    max-width: 100%;

  }
}

.modbox {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smbox {
  min-width: 300px;
  height: auto;
  background-color: white;
  padding: 15px;
}

.class_center {
  width: 100%;
  min-height: 100vh;
  padding: 75px 0px 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class_center_80 {
  width: 100%;
  min-height: 80vh;
  padding: 75px 0px 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class_cen {
  width: 100%;
  min-height: 100vh;
  padding: 75px 0px 35px 0px;
}

.whole__page {

  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 18%;
  height: 100vh;
  background: white;

}

.side_navbar_inner a {

  display: block;
  color: black;
  padding: 10px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  margin: 5px;

}

.side_navbar_inner .active {
  background-color: #a1f0b8;
}

.side_navbar_inner a:hover {
  text-decoration: none;
}

.side_navbar_inner {
  margin-top: 100px;

}

.main_div {
  position: absolute;
  top: 0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  background-color: ghostwhite;
  padding: 100px 0px 50px 0px;
}

.main_inner_div {
  width: 95%;
  display: block;
  margin: auto;
}

.resp_dis {
  display: none !important;
}

@media (max-width:776px) {
  .main_div {
    position: relative;
  }

  .resp_dis {
    display: block !important;
  }

  .left_side_navbar {
    display: none;
  }

  .main_div {
    width: 100%;
  }

}

@media (max-width:500px) {
  .title_text {
    font-size: 30px;
  }

}

@media (max-width:400px) {
  .title_text {
    font-size: 30px;
  }

  .title_text_Sm {
    font-size: 30px;
  }
}

@media (max-width:430px) {

  .title_text_Sm {
    font-size: 30px;
  }
}

.donate_btn_box {
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 9999;
  display: none;
}

.btn-danger {
  padding: 8px 20px;
  border-radius: 30px;
  background-color: #dc0606;
  /* box-shadow: 0px 2px 5px white; */
}

.btn-danger:hover {
  background-color: #dc0606;
}

.btn-light {
  border-radius: 0;
}

@media (max-width:450px) {
  .donate_btn_box {
    display: block;
  }
}