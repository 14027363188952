.heroabout{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://s3.amazonaws.com/sswcp.org/image/outer/about+us/sswcp+about.webp');
    background-size: cover;
    background-position: center;
    position: relative;
    
}
.link_flex{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    gap: 0.5rem;
    
}
.up_div{
    background-color: #353535;
    width: 100%;
    min-height: 100vh;
    padding-top: 150px;
}

 .upp_box{  
    width: 90%;
    
    /* background-color: white; */
    /* margin-top: -100px; */
    padding: 10px;
    position: absolute;
    bottom:-250px;
    
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
}
.upp_inn_box{
    background-color: white;
    padding: 18px;
}
.upp_box p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    text-align: justify;
}

.about_left{
    position: relative;
}
.about_left .inbox{
    position: absolute;
    bottom: 50px;
    right: 50px;
}

.about_more_slide {
    background-color: #ececec;
    padding: 4rem 2rem;
}

.slick-next::before, .slick-prev::before {
    color: black;
}

.slick-slider {
    width: 100% !important;
}

.slick-slide {
    width: 100%;
    height: 100% !important;
    display: grid !important;
    place-items: center;
}

.slick-dots {
    margin-top: 3rem;
}

.ach_slide {
    height: 100%;
    padding: 1rem 2rem;
}

.card-cta {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(2, 13, 7, 0.4);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
}

@media (max-width:1024px) {
    .upp_box{
    /* top:90%; */
    height: 420px;
    bottom: -200px;
   
   
    }
    
}
@media (max-width:768px) {
    .upp_box{
    /* top:90%; */
    height: 420px;
    bottom: -20px;
   
   
    }
    
}

@media (max-width:500px) {
    .upp_box{
    bottom: -250px;
    margin-bottom: 3rem;
    
    }
    .about_left .inbox{
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    
}

.cardd img{
width: 40px;
height: 40px;
object-fit: contain;
}
.df_team{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 1rem;
}
.team_img{
    width: 100%;
    height: 350px;
    display: block;
    margin: auto;
    object-fit: cover;
}
.heroabout .card{
    border: none !important;
    border-radius: 0 !important;
}
.team_card_text{
    margin-top: -30px;
    background-color: #0b6240;
    padding: 5px;
    color:white;
}

.part_img{
    width: 100%;
    height: 150px;
    object-fit: contain;
}
.slick-slider{
    width: 90%;
    display: block;
    margin: auto;
}
.part_div{
    width: 100%;
   /* background: #000; */
}

/* .slick-dots{
    bottom: -6px !important;
} */


.pop_up_box{
    width: 100%;
    height:auto;
    position: absolute;
    top: 0;
    left: 0;
    background:white;
    z-index: 9999;
    text-align: justify;
}
.pop_box_hero{
    width: 100%;
    height: 100vh;
    position: relative;
    border-radius: 15px;
}

.icon_arrow{
    position: absolute;
    top: 70px;
    left: 70px;
}
.icon_arrow i{
    font-size: 40px;
    color: white;
    cursor: pointer;
}
.pop_text{
    width: 100%;
    position: absolute;
    /* transform: translateY(-50%); */
    bottom:0;
    
    /* left: 42%; */
    color:white;
}
.arow_img_ul{
    list-style-type: none;
}
.arow_img_ul li{
    margin: 25px 0;
}
.arow_img{
    width: 40px;
    height: 40px;
}

