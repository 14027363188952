footer{
    background: #000;
    width: 100%;
    min-height: 10vh;
    padding: 35px 0px 0px 0px;
}
footer ul{
    list-style-type: none;
}
footer ul li{
    margin-bottom: 10px;
    font-size: 14px;
}
footer ul li a{
    color:white !important;
}
.lat_foot{
    display: flex;
    justify-content: center;
    align-items: center;
}
.social_link{
    display: flex;
    
}
.social_link a{
    margin:0px 20px;
    /* background:rgb(2, 88, 2); */
    color:white;
    /* margin: 3px; */
    font-size: 30px;
    
}
.last_footer{
    width: 100%;
    min-height: 7vh;
    /* padding: 25px 0; */
}
.last_footer img{
    
    height: 150px;
}
.fot_logo{
    width: 280px;
    height: 200px;
    object-fit: contain;
}
@media (max-width:994px) and (min-width:776px) {
    .last_footer img{
    
        width: 90%;
    }
}

@media (max-width:450px) and (min-width:250px) {
    .last_footer img{
    
        width: 100%;
    }
}


.has-search .form-control {
    padding-left: 2.375rem;
    width: 200px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}