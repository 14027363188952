.blog_100_sec {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_100_sec .container {
  padding-top: 110px;
}

.blog_post_immmg {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.author_div {
  display: flex;
  align-items: center;
  justify-items: center;
}

.author_div img {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
}

.blog_img_post {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.blog .card {
  box-shadow: 0px 1px 3px grey;
}

.blog_p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* text-align: justify; */
}

.blog_h4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.title_text_blog {
  font-size: 32px;
}

.neew_sec {
  width: 100%;
  min-height: 100vh;
  padding: 50px 0;
}

.tab_sec .card {
  box-shadow: 0px 2px 5px grey;
}

.tab_sec .container-fluid {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 50vh;
  /* background: #f1f1f1; */
  /* padding: 50px 0; */
  /* margin: 100px auto 0; */
  word-break: break-all;
  /* border: 1px solid rgba(0, 0, 0, 0.274); */
}

.own_box {
  display: none !important;
}

.tab_box {
  display: block;
}

.block-tabs {
  display: flex;
  flex-wrap: wrap;
}

.tabs {
  padding: 8px 25px;
  cursor: pointer;
  outline: none !important;
  border-radius: 0 !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.274);
  border: none !important;
  position: relative;
  color: grey;
  /* padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none; */
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  border-bottom: 1px solid transparent;
  color: black;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  /* background: rgb(88, 147, 241); */
}

.content-tabs {
  flex-grow: 1;
}

.tabcontent {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.tabcontent h2 {
  padding: 0px 0 5px 0px;
}

.tabcontent hr {
  width: 100px;
  height: 2px;
  margin-bottom: 5px;
}

.tabcontent p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

@media (max-width:800px) {}