.whatcompo{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://sswcp.org/image/Group 56430.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}
.whatcompo h1{
    font-size: 55px;
    font-weight: 800;
}
.what_com_img{
    display: block;
    margin: auto;
}
.what_com_about{
    width: 100%;
    min-height: 50vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('http://localhost:4551/image/Group 56430.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.what_com_about h1{
    color: white;
    font-weight: 800;
}