.calltoaction{
    width: 100%;
    min-height: 20vh;
    background: #313030;
    padding: 35px 0;
}


.calltoaction .input-group {
    max-width:500px !important;
    width: 90%;
   
    border-radius: 15px;
    background: rgb(247, 245, 245);
    margin: auto;
    padding: 3px;
    
}



.calltoaction .form-control {
    border:0 !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin: 2px;
    background-color: transparent;
}
.calltoaction .input-group-text  {
width: auto;
background: #034727;
border: 0 !important;
color: #fff;
border-radius: 15px !important;
    box-shadow: none !important;
    padding: 0 15px !important;
}

@media (max-width:500px) {
 .calltoaction h3{
    font-size: 25px;
 }   
}