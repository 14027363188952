@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Merriweather', serif !important;
}

p {
  margin: 0;
}

.bg_light {
  background-color: rgba(234, 245, 240, 0.5);
}

.fn_40 {
  font-size: 40px;
}

.fn_50 {
  font-size: 60px !important;
}

.mig {
  margin-top: -10px;
}

.fn-color {
  color: green;
}

.fn_14 {
  font-size: 14px;
}

.fn_12 {
  font-size: 12px;
}

.fn_10 {
  font-size: 10px;
}

.fn_18 {
  font-size: 18px;
}

.fn_24 {
  font-size: 24px;
}

.fn_20 {
  font-size: 20px;
  line-height: 45px;
}

.fb_600 {
  font-weight: 600;
}

.fb_700 {
  font-weight: 700;
}

.text_grey {
  color: rgb(89, 89, 89);
}

.title_text {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 3px;
}

.about_text {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 5px;
}

.title_text_Sm {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 3px;
}

.small_text {
  font-size: 30px;
}

.low_border {
  position: relative;

}

.low_border_inn {
  position: absolute;
  width: 100px;
  height: 5px;
  background-color: #0B6240;
  margin-top: -10px;
}

.normal_font {
  word-spacing: 8px;
  font-size: 16px;
}

.text-custom {
  color: #0B6240 !important;

}

.btn-primary {
  background-color: #0B6240 !important;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 30px;
}

.btn-primary_black {
  background-color: #353535 !important;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;

}

a:hover {
  text-decoration: none !important;
}

.ex_h {
  height: 400px !important;
}

.btn-primary:hover {
  background-color: #038e38 !important;
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}

.btn-one {
  background: #0a4260 !important;
  padding: 7px 13px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;


}

.btn-one-mix {
  background: linear-gradient(to right, #0b5075, #3792c2) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.btn-onee-mix {
  background: linear-gradient(to right, #0b5075, #3792c2) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.btn-two {
  background: #8b0303 !important;
  padding: 7px 13px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;

}

.btn-two-mix {
  background: linear-gradient(130deg, #dc0606, yellow) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.btn-three {
  background: #a60357 !important;
  padding: 7px 13px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;

}

.btn-three-mix {
  background: linear-gradient(to right, #c20366, #f32424) !important;
  padding: 7px 20px;
  border-radius: 0;
  font-weight: 500;
  border: none !important;
  color: #fff !important;
  width: 170px;
}

.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}



.blank_page {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(199, 194, 194);
}

.cur {
  cursor: pointer !important;
}

.mbneg {
  margin-bottom: -7px;
}

.matchcom {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-wrap: wrap;
}

.masmall {
  padding: 2px 7px;
  background: grey;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  font-size: 10px;
  margin: 1px;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-warning {
  background-color: #C1F7D5 !important;
  border: none !important;
  border-radius: 15px;
}

.form-control {
  border: 1px solid #E0E0E0 !important;

}

.form-control:focus {

  box-shadow: none;
}

.wid {
  max-width: 70%;
  display: block;
  margin: auto;
}

@media (max-width:500px) {
  .wid {
    max-width: 100%;

  }

  .about_text {
    font-size: 35px !important;
    font-weight: 700;
    letter-spacing: 3px;
  }

  .small_text {
    font-size: 25px;
  }
}

@media (min-width:750px) and (max-width:1000px) {
  .wid {
    max-width: 100%;

  }
}

.modbox {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smbox {
  min-width: 300px;
  height: auto;
  background-color: white;
  padding: 15px;
}

.class_center {
  width: 100%;
  min-height: 100vh;
  padding: 75px 0px 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class_center_80 {
  width: 100%;
  min-height: 80vh;
  padding: 75px 0px 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class_cen {
  width: 100%;
  min-height: 100vh;
  padding: 75px 0px 35px 0px;
}

.whole__page {

  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 18%;
  height: 100vh;
  background: white;

}

.side_navbar_inner a {

  display: block;
  color: black;
  padding: 10px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  margin: 5px;

}

.side_navbar_inner .active {
  background-color: #a1f0b8;
}

.side_navbar_inner a:hover {
  text-decoration: none;
}

.side_navbar_inner {
  margin-top: 100px;

}

.main_div {
  position: absolute;
  top: 0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  background-color: ghostwhite;
  padding: 100px 0px 50px 0px;
}

.main_inner_div {
  width: 95%;
  display: block;
  margin: auto;
}

.resp_dis {
  display: none !important;
}

@media (max-width:776px) {
  .main_div {
    position: relative;
  }

  .resp_dis {
    display: block !important;
  }

  .left_side_navbar {
    display: none;
  }

  .main_div {
    width: 100%;
  }

}

@media (max-width:500px) {
  .title_text {
    font-size: 30px;
  }

}

@media (max-width:400px) {
  .title_text {
    font-size: 30px;
  }

  .title_text_Sm {
    font-size: 30px;
  }
}

@media (max-width:430px) {

  .title_text_Sm {
    font-size: 30px;
  }
}

.donate_btn_box {
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 9999;
  display: none;
}

.btn-danger {
  padding: 8px 20px;
  border-radius: 30px;
  background-color: #dc0606;
  /* box-shadow: 0px 2px 5px white; */
}

.btn-danger:hover {
  background-color: #dc0606;
}

.btn-light {
  border-radius: 0;
}

@media (max-width:450px) {
  .donate_btn_box {
    display: block;
  }
}
/* Googlefont Poppins CDN Link */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body{
  min-height: 100vh;
}
nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 90px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
nav .navbar{
  height: 100%;
  max-width: 1400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  /* background: red; */
  padding: 0 50px;
}
.navbar .logo a{
  font-size: 30px;
  color: black;
  text-decoration: none;
  font-weight: 600;
}
nav .navbar .nav-links{
  line-height: 90px;
  height: 100%;
}
nav .navbar .links{
  display: flex;
}

nav .navbar .links li{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 14px;
}
nav .navbar .links li a{
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: black;
  font-size: 15px;
  font-weight: 500;
  font-size: 14px;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  }

nav .navbar .links li .arrow{
  /* background: red; */
  height: 100%;
  width: 22px;
  line-height: 90px;
  text-align: center;
  display: inline-block;
  color: black;
  transition: all 0.3s ease;
}
nav .navbar .links li .sub-menu{
  position: absolute;
  top: 70px;
  left: 0;
  line-height: 40px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
  /* width: 100%; */
}
nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu{
  display: block;
}
.navbar .links li .sub-menu li{
  padding: 0 22px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.navbar .links li .sub-menu a{
  color: black;
  font-size: 14px;
  font-weight: 500;
}
.navbar .links li .sub-menu .more-arrow{
  line-height: 40px;
}
.navbar .links li .htmlCss-more-sub-menu{
  /* line-height: 40px; */
}
.navbar .links li .sub-menu .more-sub-menu{
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}
.links li .sub-menu .more:hover .more-sub-menu{
  display: block;
}
.navbar .search-box{
  position: relative;
   height: 40px;
  width: 130px;
  /* background: #a44444; */
  display: flex;
  justify-content: space-between !important;
  
}

.navbar .search-box i{
  /* position: absolute; */
  height: 100%;
  /* width: 100%; */
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.navbar .search-box .input-box{
  position: absolute;
  right: calc(100% - 40px);
  top: 80px !important;
  height: 60px;
  width: 300px;
  background: grey;
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}
.navbar.showInput .search-box .input-box{
  top: 65px;
  opacity: 1;
  pointer-events: auto;
  background: grey;
}
.search-box .input-box::before{
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  background: grey;
  right: 10px;
  top: -6px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.search-box .input-box input{
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 35px;
  width: 280px;
  outline: none;
  padding: 0 15px;
  font-size: 16px;
  border: none;
}
.navbar .nav-links .sidebar-logo{
  display: none;
}
.navbar .bx-menu{
  display: none;
}
.smal_dis{
  display: block;
}
@media (max-width:920px) {
  nav .navbar{
    max-width: 100%;
    padding: 0 25px;
  }

  nav .navbar .logo a{
    font-size: 27px;
  }
  nav .navbar .links li{
    padding: 0 10px;
    white-space: nowrap;
  }
  nav .navbar .links li a{
    font-size: 15px;
  }
}
@media (max-width:800px){
  nav{
    /* position: relative; */
  }
  .navbar .bx-menu{
    display: block;
  }
  nav .navbar .nav-links{
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    max-width: 270px;
    width: 100%;
    background:  white;
    line-height: 40px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    z-index: 1000;
  }
  .navbar .nav-links .sidebar-logo{
   
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-logo .logo-name{
    font-size: 25px;
    color: black;
  }
    .sidebar-logo  i,
    .navbar .bx-menu{
      font-size: 25px;
      color: black;
    }
  nav .navbar .links{
    display: block;
    margin-top: 20px;
  }
  nav .navbar .links li .arrow{
    line-height: 40px;
  }
nav .navbar .links li{
    display: block;
  }
nav .navbar .links li .sub-menu{
  position: relative;
  top: 0;
  box-shadow: none;
  display: none;
  /* width: 500px; */
}
nav .navbar .links li .sub-menu li{
  border-bottom: none;

}
.navbar .links li .sub-menu .more-sub-menu{
  display: none;
  position: relative;
  left: 0;
}
.navbar .links li .sub-menu .more-sub-menu li{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow{
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  .navbar .links li .sub-menu .more-sub-menu{
    display: none;
  }
  .navbar .links li .sub-menu .more span{
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu{
    display: none;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu{
    display: none;
  }
.navbar .nav-links.show1 .links .htmlCss-sub-menu,
  .navbar .nav-links.show3 .links .js-sub-menu,
  .navbar .nav-links.show2 .links .more .more-sub-menu{
      display: block;
    }
    .navbar .nav-links.show1 .links .htmlcss-arrow,
    .navbar .nav-links.show3 .links .js-arrow{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
}
    .navbar .nav-links.show2 .links .more-arrow{
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
}
@media (max-width:370px){
  nav .navbar .nav-links{
  max-width: 90%;
} 
}
.no_view{
  display: none !important;
}
@media (max-width:450px){
  .smal_dis{
    display: none;
  }
  .no_view{
    display: block !important;
  }
  .search-box{
    position: relative;
    height: 40px;
    width: 40px !important;
    /* background-color: #818181; */
  }
  .navbar .search-box i{
    position: absolute;
    /* right: -50px; */
    height: 100%;
    width: 100%;
    line-height: 40px;
    text-align: center;
    font-size: 22px;
    color: black;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

.logoimg{
    width: 80%;
    height: 100px;
    object-fit: contain;
   
}
.logoimg1{
    width: 70px;
    height: 70px;
    -webkit-filter: drop-shadow(0px 1px 2px #cbc6c6);
            filter: drop-shadow(0px 1px 2px #cbc6c6);
}
.is_vis{
display: block ;
}
.no_vis{
  display: none !important;
}

@media (max-width:1060px) and (min-width:800px) {
  .no_vis{
    display: block !important;
  }
  .is_vis{
    display: none !important;
  }
}



.activenav{
  color:red !important;
  /* border-bottom: 2px solid red; */
  
}




.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_box{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_box input{
  max-width: 500px;
  width: 90%;
  height: 50px;
  border: none !important;
  outline: none !important;
  padding-left: 10px;
  background: transparent;
  border-bottom: 2px solid white !important;
  color:white;
}
.overlay-content ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  
}
.overlay-content ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}


.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay button{
  border: none !important;
  outline: none !important;
}
.overlay button:focus{
  outline: none !important;
  border: none !important;
}
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 30px;
  background: transparent !important;
  color: white;
  cursor: pointer;

}


@media screen and (max-height: 450px) {
  .overlay {overflow-y: auto;}
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
  
}



footer{
    background: #000;
    width: 100%;
    min-height: 10vh;
    padding: 35px 0px 0px 0px;
}
footer ul{
    list-style-type: none;
}
footer ul li{
    margin-bottom: 10px;
    font-size: 14px;
}
footer ul li a{
    color:white !important;
}
.lat_foot{
    display: flex;
    justify-content: center;
    align-items: center;
}
.social_link{
    display: flex;
    
}
.social_link a{
    margin:0px 20px;
    /* background:rgb(2, 88, 2); */
    color:white;
    /* margin: 3px; */
    font-size: 30px;
    
}
.last_footer{
    width: 100%;
    min-height: 7vh;
    /* padding: 25px 0; */
}
.last_footer img{
    
    height: 150px;
}
.fot_logo{
    width: 280px;
    height: 200px;
    object-fit: contain;
}
@media (max-width:994px) and (min-width:776px) {
    .last_footer img{
    
        width: 90%;
    }
}

@media (max-width:450px) and (min-width:250px) {
    .last_footer img{
    
        width: 100%;
    }
}


.has-search .form-control {
    padding-left: 2.375rem;
    width: 200px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.hero-slider-next-button:focus,
.hero-slider-previous-button:focus {
  outline: none;
}

.new_sec {
  width: 100%;
  min-height: 50vh;
  padding: 2% 0;
}



.box-grey {
  width: 100%;
  min-height: 50vh;
  padding: 50px 0;
  /* background-color: rgba(233, 242, 237,0.8); */
}
.testi_flex {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}
.testi_flex h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: -10px;
}
.about_us_home_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.what_we_do_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.home-card-cta {
  display: flex;
  justify-content: start;
  align-items: end;
  margin-top: -57px;
}

.up_card_text {
  padding: 10px;
  background-color: rgba(2, 13, 7, 0.4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.up_card_textt {
  margin-top: -58px !important;
}
.up_card_text h4 {
  color: white;
}
.up_card_text a {
  font-size: 22px;
  color: white;
  margin-left: 10px;
}

.engage-cta {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  grid-gap: 10px;
  gap: 10px;
}

.image_div {
  width: 100%;
  min-height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
.image_div1 {
  width: 100%;
  min-height: 80vh;
  /* background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://sswcp.org/image/wepik-export-20230502203143.png'); */

  display: flex;
  justify-content: center;
  align-items: center;
}
.flag_div {
  text-align: center;
}
.flag_img {
  width: 50%;
  height: 230px;
  object-fit: contain;
}

.text_under {
  position: relative;
}
.text_under::after {
  content: '';
  position: absolute;
  display: block;
  background-color: #0b6240;
  height: 6px;
  width: 120px;
  left: 50%;
  bottom: -10px;
}

.slick-slide {
  width: 100%;
  box-sizing: border-box;
  /* display: block; */
  /* margin: 0 27px; */
  padding: 5px;
  /* height: 350px; */
  overflow: hidden;
  /* box-shadow:  1px 3px 5px rgba(0,0,0,0.4); */
}

.testi_img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  /*  */
}

.slick-arrow {
  background-color: white !important;
  width: 35px !important;
  height: 35px !important;
  padding: 5px !important;
  border-radius: 50%;
  box-shadow: 0px 1px 2px grey;
}
.card_box {
  width: 100%;
  /* background: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: justify; */
  padding: 5px;
  box-shadow: 0px 1px 2px grey;
}
@media (max-width: 500px) {
  .card_box {
    flex-direction: column !important;
  }
}

.sm_blog_img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.hero_img {
  width: 100%;
  height: 100vh;

  overflow: hidden;
  position: relative;
}
.hero_img img {
  width: 100%;
  height: 100vh;
}

.hero_img div {
  position: absolute !important;
  max-width: 700px !important;
  width: 90%;
  height: auto;
  padding: 15px;

  /* background: #000; */
  top: 80%;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.hero_img div h1 {
  color: white;
  font-weight: 800 !important;
}

.owl-dots {
  margin-top: -10px;
  z-index: 9999 !important;
}

.index-module_Wrapper__-jUiF {
  display: none !important;
}

.slick-dots {
  position: absolute;
  bottom: -17px !important;
}

.calltoaction{
    width: 100%;
    min-height: 20vh;
    background: #313030;
    padding: 35px 0;
}


.calltoaction .input-group {
    max-width:500px !important;
    width: 90%;
   
    border-radius: 15px;
    background: rgb(247, 245, 245);
    margin: auto;
    padding: 3px;
    
}



.calltoaction .form-control {
    border:0 !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin: 2px;
    background-color: transparent;
}
.calltoaction .input-group-text  {
width: auto;
background: #034727;
border: 0 !important;
color: #fff;
border-radius: 15px !important;
    box-shadow: none !important;
    padding: 0 15px !important;
}

@media (max-width:500px) {
 .calltoaction h3{
    font-size: 25px;
 }   
}
.modal_box{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(5, 30, 48,0.5);
    position:fixed;
    z-index: 999999;
}

.modal_inn_box{
    max-width: 90%;
    width:500px ;
    height: 300px;
    
    /* border: 4px solid red; */
    position: relative;
    background-color: rgb(237, 245, 242);
}

.modal_img{
    width: 100%;
height:100%;
}
.modal_button{
    position: absolute;
    bottom:  50px;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    -webkit-transform: translate(-50%, 0%);

            transform: translate(-50%, 0%)
}

.cross_box{
    width: 25px;
    height: 25px;
    background-color: green;
    color:white;
    position: absolute;
    top:5px;
    right:5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
}

.modl .input-group {
    /* max-width:500px !important; */
    width: 90%;
   
    border-radius: 15px;
    background: white;
    margin: auto;
    padding: 3px;
    
}

.modl  .form-control {
    border:0 !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin: 2px;
    background-color: transparent;
}
.modl  .input-group-text  {
width: auto;
background: #034727;
border: 0 !important;
color: #fff;
border-radius: 15px !important;
    box-shadow: none !important;
    padding: 0 15px !important;
}

@media (max-width:500px) {
    .modal_inn_box{
        width: 90%;
    }
}
.heroabout{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://s3.amazonaws.com/sswcp.org/image/outer/about+us/sswcp+about.webp');
    background-size: cover;
    background-position: center;
    position: relative;
    
}
.link_flex{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    
}
.up_div{
    background-color: #353535;
    width: 100%;
    min-height: 100vh;
    padding-top: 150px;
}

 .upp_box{  
    width: 90%;
    
    /* background-color: white; */
    /* margin-top: -100px; */
    padding: 10px;
    position: absolute;
    bottom:-250px;
    
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.upp_inn_box{
    background-color: white;
    padding: 18px;
}
.upp_box p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    text-align: justify;
}

.about_left{
    position: relative;
}
.about_left .inbox{
    position: absolute;
    bottom: 50px;
    right: 50px;
}

.about_more_slide {
    background-color: #ececec;
    padding: 4rem 2rem;
}

.slick-next::before, .slick-prev::before {
    color: black;
}

.slick-slider {
    width: 100% !important;
}

.slick-slide {
    width: 100%;
    height: 100% !important;
    display: grid !important;
    place-items: center;
}

.slick-dots {
    margin-top: 3rem;
}

.ach_slide {
    height: 100%;
    padding: 1rem 2rem;
}

.card-cta {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(2, 13, 7, 0.4);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: white;
}

@media (max-width:1024px) {
    .upp_box{
    /* top:90%; */
    height: 420px;
    bottom: -200px;
   
   
    }
    
}
@media (max-width:768px) {
    .upp_box{
    /* top:90%; */
    height: 420px;
    bottom: -20px;
   
   
    }
    
}

@media (max-width:500px) {
    .upp_box{
    bottom: -250px;
    margin-bottom: 3rem;
    
    }
    .about_left .inbox{
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    
}

.cardd img{
width: 40px;
height: 40px;
object-fit: contain;
}
.df_team{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 1rem;
}
.team_img{
    width: 100%;
    height: 350px;
    display: block;
    margin: auto;
    object-fit: cover;
}
.heroabout .card{
    border: none !important;
    border-radius: 0 !important;
}
.team_card_text{
    margin-top: -30px;
    background-color: #0b6240;
    padding: 5px;
    color:white;
}

.part_img{
    width: 100%;
    height: 150px;
    object-fit: contain;
}
.slick-slider{
    width: 90%;
    display: block;
    margin: auto;
}
.part_div{
    width: 100%;
   /* background: #000; */
}

/* .slick-dots{
    bottom: -6px !important;
} */


.pop_up_box{
    width: 100%;
    height:auto;
    position: absolute;
    top: 0;
    left: 0;
    background:white;
    z-index: 9999;
    text-align: justify;
}
.pop_box_hero{
    width: 100%;
    height: 100vh;
    position: relative;
    border-radius: 15px;
}

.icon_arrow{
    position: absolute;
    top: 70px;
    left: 70px;
}
.icon_arrow i{
    font-size: 40px;
    color: white;
    cursor: pointer;
}
.pop_text{
    width: 100%;
    position: absolute;
    /* transform: translateY(-50%); */
    bottom:0;
    
    /* left: 42%; */
    color:white;
}
.arow_img_ul{
    list-style-type: none;
}
.arow_img_ul li{
    margin: 25px 0;
}
.arow_img{
    width: 40px;
    height: 40px;
}


.blog_100_sec {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_100_sec .container {
  padding-top: 110px;
}

.blog_post_immmg {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.author_div {
  display: flex;
  align-items: center;
  justify-items: center;
}

.author_div img {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
}

.blog_img_post {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.blog .card {
  box-shadow: 0px 1px 3px grey;
}

.blog_p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* text-align: justify; */
}

.blog_h4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.title_text_blog {
  font-size: 32px;
}

.neew_sec {
  width: 100%;
  min-height: 100vh;
  padding: 50px 0;
}

.tab_sec .card {
  box-shadow: 0px 2px 5px grey;
}

.tab_sec .container-fluid {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 50vh;
  /* background: #f1f1f1; */
  /* padding: 50px 0; */
  /* margin: 100px auto 0; */
  word-break: break-all;
  /* border: 1px solid rgba(0, 0, 0, 0.274); */
}

.own_box {
  display: none !important;
}

.tab_box {
  display: block;
}

.block-tabs {
  display: flex;
  flex-wrap: wrap;
}

.tabs {
  padding: 8px 25px;
  cursor: pointer;
  outline: none !important;
  border-radius: 0 !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.274);
  border: none !important;
  position: relative;
  color: grey;
  /* padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none; */
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  border-bottom: 1px solid transparent;
  color: black;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  /* background: rgb(88, 147, 241); */
}

.content-tabs {
  flex-grow: 1;
}

.tabcontent {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.tabcontent h2 {
  padding: 0px 0 5px 0px;
}

.tabcontent hr {
  width: 100px;
  height: 2px;
  margin-bottom: 5px;
}

.tabcontent p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

@media (max-width:800px) {}
.progCompotop{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://s3.amazonaws.com/sswcp.org/image/outer/our+program/sswcp+program.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progCompotop h1{
    font-size: 100px;
    color:white;
    font-weight: 800;
}
.prog_img{
    width: 100%;
    height: 270px;
    object-fit: cover;
    /* filter: grayscale(1); */
}

.square {
    /* margin: 0 auto; */
    /* width: 80%; */
    height: 80%;
    aspect-ratio: 1/1;
    object-fit: cover;
}
@media (max-width:600px) {
    .progCompotop h1{
        font-size: 60px;
        color:white;
        font-weight: 800;
    }
}

@media (min-width: 1024px) {
    .square {
        width: 80%;
    }
}   
.whatcompo{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('https://sswcp.org/image/Group 56430.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}
.whatcompo h1{
    font-size: 55px;
    font-weight: 800;
}
.what_com_img{
    display: block;
    margin: auto;
}
.what_com_about{
    width: 100%;
    min-height: 50vh;
    background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.3)),url('http://localhost:4551/image/Group 56430.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.what_com_about h1{
    color: white;
    font-weight: 800;
}
.engage {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url('https://s3.amazonaws.com/sswcp.org/image/outer/engage+with+us/sswcp+engage+with+us.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.engage .boxx {
  /* max-width: 650px !important;
    width: 90% ; */
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
.dd-flex {
  display: flex;
}
.surimg {
  object-fit: contain;

  height: 200px;
  display: block;
  margin-left: auto;
}
.row_sur {
  align-items: center;
}
.eng_contact {
  width: 100%;
  min-height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url('https://s3.amazonaws.com/sswcp.org/image/outer/engage+with+us/contact+us.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center_box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center_boxx {
  align-items: self-end !important;
}

.team_work_img {
  width: 120px;
  height: 120px;
  display: block;
  margin: auto;

  /* object-fit: cover; */
}

.event-card {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.event-card-date {
  margin-inline: 2rem;
  padding-inline: 1rem;
  font-size: 1.6rem;
}

.event-card-details {
  border-left: solid 2px #bdbdbd;
  border-top: none;
  max-width: -webkit-max-content;
  max-width: max-content;
}

.event-time-location {
  width: 50%;
  justify-content: space-between;
  align-items: center;
}

.event-time-location > div {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}

@media (max-width: 768px) {
  .event-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .event-card-date {
    width: 100%;
  }

  .event-card-details {
    border-top: solid 2px #bdbdbd;
    border-left: none;
  }

  .event-time-location {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .dd-flex {
    display: block;
  }
}
.page_box{
    width: 100%;
    min-height: 100vh;
    position: relative;
}
.privac h4{
    font-weight: 700;
}
.table-borderless{
    border: none !important;
}
table td , table th{
    border-top: none !important;
}
.pri_ul{
    list-style-type: none;
    padding-left: 15px;
}
.pri_ul li{
    margin: 10px 0;
}

.annual{
    padding-top: 150px;
    padding-bottom: 50px;
}
.annul_img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.annual .card{
    position: relative;
}

.upp_card_text{
    /* margin-top: -115px; */
    width: 100%;
    height: auto;
position: absolute;
bottom: 0;
    padding:10px;
    background-color: rgba(2, 13, 7, 0.4);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upp_card_text h4{
    color: white;

}
.upp_card_text a{
    font-size: 22px;
    color: white;
}
.hh_t{
    font-size: 100px;
}

.contact{
    width: 100%;
    min-height: 100vh;
    padding: 50px 0;
    border-radius: 15px;
}


.contact_inn{
    padding-top: 150px;
}

.pay_card{
    background-color: #354463;
    padding: 25px;
    
}
.pay_card .form_h{
    height: 50px;
    border: none !important;
}
.td_apply{
    width: 120px;
    /* background-color: #354463; */
}
.cur_tbody{
    border: 2px dashed rgb(205, 203, 203);
}
.pay_text{
    font-size: 65px;
  font-weight: 700;
  letter-spacing: 5px;
}

.search .card{
    border-radius: 0 !important;
    box-shadow: 0px 2px 5px grey;
    padding: 5px;
}
.search_row{
    display: flex;
    
}
.search_row_img{
    width: 220px;
    height: 200px;
    object-fit: cover;
    display: block;
    margin: auto;
    
}
.search_row a{
    color:black;
}

.search_row a:hover{
color:rgb(2, 81, 2) !important;
text-decoration: none;
}
.search_row h5{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
.search_row p{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* text-align: justify; */
}
@media (max-width:800px) {
    .pay_text{
       font-size: 45px;
    }
}
@media (max-width:600px) {
    .pay_text{
       font-size: 40px;
    }
    
}
@media (max-width:520px) {
    .search_row_img{
        width: 100%;
        height: 250px;
    }
    .search_row{
        flex-direction: column;
    }
}
@media (max-width:450px) {
    .pay_text{
       font-size: 35px;
    }
    
}
